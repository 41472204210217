* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #faf9f8;
  border: 1px solid #edebe9;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #e1dfdd;
  border-radius: 20px;
  /* box-shadow: 0px 2px 2px 2px #00000028; */
}

body, html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faf9f8;
  /* height: 100vh; */
  /* color: #605e5c; */
  /* background-image: url('./images/topography.png') !important; */
  /* background-repeat: repeat; */
  /* overflow: hidden; */
}

/* #root {
  height: 100%;
} */

#container {
  /* margin-top: 48px;
  height: calc(100vh - 48px);
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory; */
  /* display: flex; */
  /* flex-direction: column; */
}

.dashboard {
  width: 1200px;
  max-width: 100%;
  margin: 64px auto;
  padding: 0px 34px;
}

.hero-btn {
  padding: 16px 32px;
  width: 240px;
  border-radius: 8px;
  background: #0078d4;
  color: #fff;
  outline: none;
  border: 1px solid #0078d4;
  /* border-radius: 2px; */
  /* font-weight: 600; */
  font-size: 24px;
  cursor: pointer;
}

.hero-btn:hover {
  background: #106ebe;
  border-color: #005a9e;
}

.center {
  height: 100vh;
  /* width: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-form {
  width: 500px;
}

.signup-form .logo {
  font-size: 48px;
  /* width: 500px; */
  /* max-width: 500px; */
}

.page-header {
  font-size: 32px;
  margin-bottom: 32px;
}

.pm-container {
  /* width: 100%; */
  max-width: 1000px;
  margin: 0px auto;
  padding: 68px 24px 24px 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pointer {
  cursor: pointer;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.home-page {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.image-block {
  width: 33.33333%;
  background: #eaeaea;
  height: 100%;
  transition: width 0.2s ease-in-out;
}

.image-block:hover {
  width: 50%;
  /* background: blue !important; */
  cursor: pointer;
  z-index: 1;
  box-shadow: 0px 0px 12px 0px #00000050;
}

.image-block.cms {
  background-image: url('./images/cheakamus-cover.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.image-block.asr {
  background-image: url('./images/alouette-cover.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.image-block.wal {
  background-image: url('./images/waleach-cover.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.fog {
  height: 100%;
  width: 100%;
  background: #00000063;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fog:hover {
  background: none;
}

.fog h1 {
  color: #fff;
  letter-spacing: 4px;
  /* text-transform: uppercase; */
  margin: 0px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  text-shadow: 0px 0px 3px rgb(31, 31, 31);
  transition: all 0.2s ease-in-out;
}

.fog:hover h1 {
  font-size: 24px;
}

.nechako-cover {
  background-image: url('./images/nechako-cover.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

.csamp-cover {
  background-image: url('./images/csamp-cover.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

.big-bar-cover {
  background-image: url('./images/big-bar-cover.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

.cheakamus-cover {
  background-image: url('./images/cheakamus-cover.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

.asr-cover {
  background-image: url('./images/alouette-cover.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

.wahleach-cover {
  background-image: url('./images/waleach-cover.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

.circle-picker {
  width: 100% !important;
}

.glass {
  box-shadow: 0 0 1rem 0 rgb(0 0 0 / 20%);
  background: #ffffff29;
  backdrop-filter: blur(8px);
  border-radius: 2px;
  padding: 32px;
}

.bg-white {
  background: #fff;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
}

.br-none {
  border-radius: 0px;
}

.spacer {
  margin-top: 32px;
  margin-bottom: 32px;
  background: #dbd9d7;
  height: 1px;
}

.glass:before {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
  content: '';
  /* height: 100%; */
  position: absolute;
  /* width: 100%; */
}

.cover-text {
  font-size: 64px;
  font-weight: 600;
  color: #fff;
}

.cover-sub {
  font-size: 32px;
  /* color: #fff; */
}

.cover-sub a {
  text-decoration: underline;
  color: inherit;
}

.logo, .title {
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 18px;
  font-weight: 400;
  /* text-transform: uppercase; */
}

h1, h2, h3, h4, h4, h5 {
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  /* text-transform: uppercase; */
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 14px;
}

h4, h5, h6 {
  font-size: 12px;
}

.loading-blocker {
  /* background: #ffffff; */
  /* transition: background 0.2s ease-in-out; */
  z-index: 421;
}

.table-row {
  border-bottom: 1px solid #dbd9d7;
}

.links {
  flex: 1;
  padding: 0px 16px;
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* font-size: 12px; */
  /* text-transform: uppercase; */
}

.links a {
  margin: 0px 6px;
}

.links a.active { 
  color: #0078d4;
}

.cover-sub a:hover {
  color: #71afe5;
}

.overlay {
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.1);
  /* background: linear-gradient(90deg, rgba(0,0,0,0.7122199221485469) 0%, rgba(0,0,9,0.19681375968356096) 45%, rgba(255,255,255,0) 100%); */
  display: flex;
  align-items: center;
  justify-content: center;
}

nav {
  background: #fff;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  height: 48px;
  z-index: 420;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
}

section {
  height: 100vh;
  max-height: 100vh;
  scroll-snap-align: center;
  padding-top: 48px;
  background: #fff;
}

.chart-area {
  display: flex;
  flex-direction: column;
}

.picker-button {
  text-align: center;
  border: 1px solid #c8c6c4;
  margin: 8px;
  width: calc(50% - 16px);
  background: #fff;
  padding: 16px 32px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 4px;
}

.picker-button.disabled {
  background: #edebe9;
  color: #666;
}

.hover:hover {
  box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108);
}

.picker-button:hover {
  background: #c7e0f4;
  border: 1px solid #0078d4;
  box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108);
}

.options {
  background: #faf9f8;
  border-top: 1px solid #edebe9;
}

.alt-add {
  border: 1px solid #6c6c60;
  padding: 4px 8px;
  margin-bottom: 8px;
}

.js-fill {
  /* filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.7)) */
}

.hover-box {
  border: 1px solid #c8c6c4;
  padding: 4px 8px;
  border-radius: 4px;
  background: #fff;
}

.hover-box-dark {
  border-color: #605e5c;
}

.hover-label {
  cursor: pointer;
  padding: 4px 8px;
  border: 1px solid #c8c6c4;
  background: #fff;
  /* font-weight: 400; */
  transition: all 0.2s ease-in-out;
  /* width: 100%; */
  /* min-height: 28px; */
  border-radius: 4px;
  display: flex;
  align-items: center;
  /* font-size: 18px; */
  /* border-radius: 20px; */
}

.hover-label.active {
  background: #c7e0f4;
}

.hover-label:hover, .hover-label.active {
  /* background: #c7e0f4; */
  border: 1px solid #0078d4;
  box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108);
}

.expand-wrapper {
  border: 1px solid #605e5c;
  border-radius: 2px;
}

.expand-btn {
  padding-left: 12px;
}

.tal {
  text-align: left;
}

.tac, .text-center {
  text-align: center;
}

.title-box {
  background: #ffffffe8;
  padding: 2rem;
  border-radius: 4px;
}

.title {
  letter-spacing: 3px;
  margin-bottom: 1rem;
  font-size: 28px;
}

a {
  color: inherit;
  text-decoration: none;
}

.add-chart {
  width: 50%;
  max-width: 460px;
}

.add-project {
  background: #deecf9;
  border: 1px solid #0078d4;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.add-project:hover {
  background: #c7e0f4;
  border: 1px solid #0078d4;
  /* color: #fff; */
}

.map-popup {
  width: 320px !important;
  max-width: 400px !important;
  min-width: 320px !important;
}

.project-title {
  font-weight: 600;
}

.block {
  display: block;
}

.project-key {
  font-size: 12px;
  color: #666;
}

/* spacing-shortcuts */
.mauto {
  margin: 0px auto;
}

.m0 {
  margin: 0px;
}

.m1 {
  margin: 1rem;
}

.m2 {
  margin: 2rem;
}

.m3 {
  margin: 3rem;
}

.m4 {
  margin: 4rem;
}

.m5 {
  margin: 5rem;
}

.mr1 {
  margin-right: 2px;
}

.mr2 {
  margin-right: 4px;
}

.mr3 {
  margin-right: 8px;
}

.mr4 {
  margin-right: 16px;
}

.ml1 {
  margin-left: 2px;
}

.ml2 {
  margin-left: 4px;
}

.ml3 {
  margin-left: 8px;
}

.ml4 {
  margin-left: 16px;
}

.mb1 {
  margin-bottom: 2px;
}

.mb2 {
  margin-bottom: 4px;
}

.mb3 {
  margin-bottom: 8px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb4 {
  margin-bottom: 16px;
}

.mb5 {
  margin-bottom: 24px;
}

.mt0 {
  margin-top: 0;
}

.mt1 {
  margin-top: 2px;
}

.mt2 {
  margin-top: 4px;
}

.mt3 {
  margin-top: 8px;
}

.pad6 {
  padding: 6px;
}

.p1 {
  padding: 1rem;
}

.p2 {
  padding: 2rem;
}

.p3 {
  padding: 3rem;
}

.p4 {
  padding: 4rem;
}

.p5 {
  padding: 5rem;
}

/* width shorts */
.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

/* flex shortcuts */

.grid {
  display: grid;
}

.col3 {
  grid-template-columns: repeat(3, 1fr);
}

.col4 {
  grid-template-columns: repeat(4, 1fr);
}

.col7 {
  grid-template-columns: repeat(7, 1fr);
}

.g4 {
  gap: 4px;
}

.g12 {
  gap: 12px;
}

.g8 {
  gap: 8px;
}

.g16 {
  gap: 16px;
}

.fr {
  display: flex;
}

.f1 {
  flex: 1;
}

.f2 {
  flex: 2;
}

.f3 {
  flex: 3;
}

.fwrap {
  flex-wrap: wrap;
}

.fcol {
  flex-direction: column;
}

.sb {
  justify-content: space-between;
}

.jcc {
  justify-content: center;
}

.aic {
  align-items: center;
}

.pm-name {
  font-size: 18px;
  margin-top: 0px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid {
  display: grid;
  
}

.grid-gap-16 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.grid-gap-12 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.grid-col-4 {
  grid-template-columns: repeat(4, 1fr);
}

.empty {
  height: 450px;
  background: #fff;
}

.end {
  align-self: flex-end;
}

.row4 {
  margin-left: -4px;
  margin-right: -4px;
}

.pm-builder-text {
  /* font-size: 18px; */
  line-height: 48px;
}

.hover-to-change {
  border-bottom: 2px solid #71afe5;
}

.round-border {
  border-radius: 2px;
  border: 1px solid;
  border-color: #dbd9d7;
}

.swatches-picker {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 400px;
}

.swatches-picker * {
  box-shadow: none !important;
}
